import { httpGetWithCsrf } from './Http';
import { keysToCamelFromSnake } from 'utils/caseconverters';
import { setUser } from 'store/user/userSlice';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';


const getUserOrRedirect = (props) => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const router = useRouter();
    const freebie = props.componentProps?.freebie;        

    useEffect(() => {
        if (!props.preview && freebie !== true) {
            if (!user.email) {
                httpGetWithCsrf(`${process.env.NEXT_PUBLIC_AUTH_API_URL}/user/`)
                .then((data) => {
                    dispatch(setUser(keysToCamelFromSnake(data)));
                }).catch((e) => {
                    console.log('error', e)
                    router.push('/account/login');
                })
            };
        }
    }, []);
}

export { getUserOrRedirect };